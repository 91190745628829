<template>
    <div class="grid">
        <div class="col-12">
            <div class="card p-fluid w-full">
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                    <AppInfoManual nomeTelaDoManual="medicos-form" />
                    <h3 class="ml-1">{{ title }}</h3>
                </div>
                <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
                <div :style="loading ? 'opacity: 30%;' : ''">
                    <Toast />

                    <div class="">
                        <div class="field">
                            <label for="name">Nome</label>
                            <InputText
                                id="name"
                                v-model.trim="form.name"
                                :class="{ 'p-invalid': submitted && !form.name }"
                                autocomplete="off"
                                autofocus
                                required="true"
                            />
                            <small v-if="submitted && !form.name" class="p-error">Nome é obrigatório.</small>
                        </div>

                        <h5 class="mt-6 mb-4">Conselho de classe</h5>

                        <div class="grid">
                            <div class="field col-3">
                                <label for="classCouncilAcronym">Sigla</label>
                                <InputText
                                    id="classCouncilAcronym"
                                    v-model.trim="form.classCouncilAcronym"
                                    :class="{ 'p-invalid': submitted && !form.classCouncilAcronym }"
                                    autocomplete="off"
                                />
                                <small v-if="submitted && !form.classCouncilAcronym" class="p-error">Sigla é obrigatório.</small>
                            </div>
                            <div class="field col-6">
                                <label for="classCouncil">Conselho de classe</label>
                                <InputText
                                    id="classCouncil"
                                    v-model.trim="form.classCouncil"
                                    :class="{ 'p-invalid': submitted && !form.classCouncil }"
                                    autocomplete="off"
                                />
                                <small v-if="submitted && !form.classCouncil" class="p-error">Conselho de classe é obrigatório.</small>
                            </div>
                            <div class="field col-3">
                                <label for="orgaoEstadoUf">UF orgão de classe</label>
                                <DropdownEstado
                                    id="orgaoEstadoUf"
                                    v-model="orgaoEstadoUf"
                                    :idValue="form.orgaoEstadoUf"
                                    optionLabel="nome"
                                    optionValue="sigla"
                                />
                            </div>
                        </div>

                        <h5 class="mt-6 mb-4">Especialidades</h5>

                        <div class="field">
                            <label for="specialty">Especialidade</label>
                            <InputText
                                id="specialty"
                                v-model.trim="form.specialty"
                                :class="{ 'p-invalid': submitted && !form.specialty }"
                                autocomplete="off"
                            />
                            <small v-if="submitted && !form.specialty" class="p-error">Especialidade é obrigatório.</small>
                        </div>
                    </div>

                    <div class="flex mt-5">
                        <Button class="p-button-primary w-6" icon="pi pi-check" label="Salvar" @click="save" />
                        <Button class="p-button-text w-3 ml-auto" icon="pi pi-times" label="Voltar" @click="$router.push('/medic')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseService from '@/services/BaseService';
import { getClientBase } from '@/services/http';
import { getCurrentCompany } from '@/services/store';
import DropdownEstado from '../esocial/eventos/components/_shared/DropdownEsocialEstado.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    components: { DropdownEstado, AppInfoManual },
    data() {
        return {
            form: {},
            submitted: false,
            profiles: [],
            loadingProfiles: false,
            loading: false,
            orgaoEstadoUf: undefined
        };
    },
    async mounted() {
        this.loading = true;
        this.$service = new BaseService('/medics');

        try {
            this.loadingProfiles = true;
            await this.loadProfiles();

            if (this.$route.params.id) {
                const { data } = await this.$service.findById(this.$route.params.id);
                this.form = data;
            }
        } catch (error) {
            this.loading = false;
            this.$toast.add({
                severity: 'error',
                summary: 'Problemas ao carregar informações!',
                detail: error.message,
                life: 3000
            });
        }
        this.loading = false;
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar médico' : 'Adicionar médico';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        },
        orgaoEstadoUf: function (newValue) {
            this.form.orgaoEstadoUf = newValue ? newValue.sigla : null;
        }
    },
    methods: {
        async save() {
            this.submitted = true;
            try {
                await this.$service.save(this.form);
                this.$toast.add({ severity: 'success', summary: 'Médico salvo com sucesso!', life: 3000 });
                this.$router.replace('list');
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar o médico!', life: 3000 });
            }
        },
        async loadProfiles() {
            const { data } = await getClientBase().get(`/profiles/combo`);
            this.profiles = data;
            this.loadingProfiles = false;
        }
    }
};
</script>

<style lang="scss" scoped></style>
